import React, { useState } from 'react'
import { useRouter } from 'next/router'

import { useTranslation } from '@mc/i18n'
import { TrackingContainer } from '@mc/visibility'

import {
  TrackingSectionContainer,
  ComingSoonMentors,
} from '../../../components'
import { ROW_TRACKING } from '../utils'
import { usePersonaEvents } from '../../../events'

import { MentorCard } from './MentorCard'
import { mentors as mockMentors, comingSoonMentors } from './mentors'
import { type Mentor } from '../../../types/mentor'

interface MentorsSectionProps {
  mentors?: Mentor[]
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
  comingSoonRowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
}

export const MentorsSection = ({
  mentors = mockMentors,
  rowAnalytics = ROW_TRACKING.yourAiMentors,
  comingSoonRowAnalytics = ROW_TRACKING.comingSoon,
}: MentorsSectionProps) => {
  const { t } = useTranslation('@mc/persona')
  const { slug: instructorSlug } = useRouter().query
  const [currentAudio, setCurrentAudio] = useState<string | null>(null)
  const setPlayingAudio = (audio: string | null) => setCurrentAudio(audio)

  const { trackPersonaRowTileViewed } = usePersonaEvents()

  const handleVisible = (mentor: Mentor, position: number) => () =>
    trackPersonaRowTileViewed({
      ...rowAnalytics,
      tilePositionIndex: position.toString(),
      tileTitle: mentor.title,
      tileSubtitle: mentor.keywords.join('  •  '),
      tileLink: mentor.redirectUrl,
      tileInstructorSlug: mentor.slug,
    })

  return (
    <>
      <TrackingSectionContainer
        analytics={rowAnalytics}
        config={{ threshold: 0.3 }}
      >
        <div className='position-relative mc-py-10'>
          <h2 className='mc-text-h1 mc-text--center mc-mb-9 mc-mx-auto'>
            {instructorSlug
              ? t('bio.mentors.title')
              : t('upcomingBetaLanding.mentors.title')}
          </h2>
          <div className='d-flex flex-gap-8 flex-wrap align-items-center justify-content-center'>
            {mentors.map((mentor, i) => (
              <TrackingContainer
                config={{ threshold: 0.3 }}
                key={`${mentor.title}-${i}`}
                onVisible={handleVisible(mentor, i)}
              >
                <MentorCard
                  mentor={mentor}
                  currentAudio={currentAudio}
                  onPlay={setPlayingAudio}
                />
              </TrackingContainer>
            ))}
          </div>
        </div>
      </TrackingSectionContainer>
      <div>
        {!!comingSoonMentors.length && (
          <TrackingSectionContainer
            analytics={comingSoonRowAnalytics}
            config={{ threshold: 0.1 }}
          >
            <div className='mc-my-10'>
              <h2 className='mc-text-h1 mc-text--center mc-mb-9 mc-mx-auto'>
                {t('upcomingBetaLanding.comingSoon.title')}
              </h2>
              <ComingSoonMentors
                mentorList={comingSoonMentors}
                comingSoonRowAnalytics={comingSoonRowAnalytics}
              />
            </div>
          </TrackingSectionContainer>
        )}
      </div>
    </>
  )
}
