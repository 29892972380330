import React, { useEffect } from 'react'

import { HeroSection } from './HeroSection'
import { InfoSection } from './InfoSection'
import { MentorsSection } from './MentorsSection'
import { UseCaseSection } from './UseCaseSection'
import { TestimonialsSection } from './TestimonialsSection'
import { PlansSection } from './PlansSection'
import { FaqSection } from './FaqSection'
import { paths } from '../../utils/paths'
import { usePersonaEvents } from '../../events'
import { VideoSection } from './VideoSection'
import { useCtaPreserveQueryParams } from '../../utils/cta'
import { Background, Footer, NavBar } from '../../components'
import { useNonCommerceGoldStandardEvents } from '../../hooks/tracking/useNonCommerceGoldStandardEvents'
import { StickyTierFooter } from '../../components/StickyTierFooter'
import { useIsLoginHidden, usePersonaVideoEnabled } from '../../experiments'
import { useTiers } from '../../components/TierProvider/TierProvider'
import { getLoggedOutFaqs } from './utils'
import { Tier } from '../../types/checkout'
import styles from './LoggedOutLandingPage.module.scss'

export type Topic = {
  text: { displayText: string; caption: string }
  analytics?: Record<string, string | number | undefined>
  redirectUrl: string
}

export type Testimonial = {
  text: string
  name: string
}

export type Faq = {
  question: string
  answer: string
}

type LoggedOutLandingPageProps = {
  topics: Topic[]
  testimonals: Testimonial[]
}

export const LoggedOutLandingPage = ({
  topics,
  testimonals,
}: LoggedOutLandingPageProps) => {
  const { trackPersonaLandingViewed } = usePersonaEvents()
  const { trackProductViewed, trackGoldStandardPageViewed } =
    useNonCommerceGoldStandardEvents('discovery')
  const getUrlWithQueryParams = useCtaPreserveQueryParams()
  const isPersonaVideoEnabled = usePersonaVideoEnabled()
  const isLoginHidden = useIsLoginHidden()
  const { monthlyTier, annualTier } = useTiers()

  const faqs = getLoggedOutFaqs(
    [monthlyTier, annualTier].filter((t): t is Tier => !!t),
  )

  // Gold Standard
  useEffect(() => {
    trackGoldStandardPageViewed()
    trackProductViewed()
  }, [trackProductViewed, trackGoldStandardPageViewed])

  useEffect(() => {
    trackPersonaLandingViewed({})
  }, [trackPersonaLandingViewed])

  return (
    <Background>
      <div className='container'>
        <NavBar showLogin={!isLoginHidden} source='discovery' />
        <HeroSection
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
        />
        {isPersonaVideoEnabled && <VideoSection />}
        <StickyTierFooter
          mobileShowAtPosition={300}
          desktopShowAtPosition={450}
          source='discovery'
        />
        <InfoSection />
        <UseCaseSection
          topics={topics}
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
        />
        <MentorsSection
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
        />
        <TestimonialsSection testimonals={testimonals} />
        <PlansSection
          ctaUrl={getUrlWithQueryParams(paths.personaCheckoutSignUp)}
          source='discovery'
        />
        <FaqSection faqs={faqs} />
        <Footer className={styles.footer} />
      </div>
    </Background>
  )
}
