import React from 'react'

import { Beta } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'
import { TrackingContainer } from '@mc/visibility'

import { type Testimonial as TestimonialType } from './LoggedOutLandingBetaPage'
import { Pagination } from './components/Pagination'
import { TrackingSectionContainer } from '../../components'
import { ROW_TRACKING } from './utils'
import { usePersonaEvents } from '../../events'

import styles from './TestimonialsSection.module.scss'

type Props = {
  testimonals: TestimonialType[]
}

const Testimonial = ({ testimonal }: { testimonal: TestimonialType }) => (
  <div className='d-flex flex-column align-items-center mc-px-9'>
    <p className='mc-text-h2 mc-text--normal mc-text--center mc-mb-6 mc-text-color--medium'>
      {testimonal.text}
    </p>
    <p className='mc-text-large mc-text-color--tint'>{testimonal.name}</p>
  </div>
)

export const TestimonialsSection = ({ testimonals }: Props) => {
  const { t } = useTranslation('@mc/persona')

  const { trackPersonaRowTileViewed, trackPersonaRowScrolled } =
    usePersonaEvents()

  return (
    <TrackingSectionContainer analytics={ROW_TRACKING.testimonials}>
      <div className='mc-py-10'>
        <div className='container d-flex flex-column align-items-center'>
          <h2
            className='mc-text-h1 mc-text--center mc-mx-auto mc-mb-9'
            style={{ zIndex: 1 }}
          >
            {t('upcomingBetaLanding.testimonials.title')}
          </h2>
          <Beta.Carousel
            loops={false}
            scrollCount={1}
            srLabel={t('upcomingBetaLanding.testimonials.title')}
          >
            <Beta.CarouselContent
              className={`row flex-gap-8 ${styles.background}`}
              scrollSnapAlign='center'
              onPositionChanged={(direction) =>
                trackPersonaRowScrolled({
                  ...ROW_TRACKING.testimonials,
                  direction,
                })
              }
            >
              {testimonals.map((testimonal, i) => (
                <div key={i} className='col-12 mc-p-0'>
                  <TrackingContainer
                    onVisible={() =>
                      trackPersonaRowTileViewed({
                        ...ROW_TRACKING.testimonials,
                        tilePositionIndex: i.toString(),
                        tileTitle: testimonal.text,
                        tileSubtitle: testimonal.name,
                      })
                    }
                  >
                    <Testimonial testimonal={testimonal} />
                  </TrackingContainer>
                </div>
              ))}
            </Beta.CarouselContent>
            <Pagination />
          </Beta.Carousel>
        </div>
      </div>
    </TrackingSectionContainer>
  )
}
