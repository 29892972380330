import React, { useRef, useEffect, useState } from 'react'
import { chunk } from 'lodash'
import {
  motion,
  useMotionValue,
  animate,
  AnimationPlaybackControls,
} from 'framer-motion'

import { useTranslation } from '@mc/i18n'
import { isMobile } from '@mc/devices'
import { TrackingContainer } from '@mc/visibility'

import { Topic } from '../LoggedOutLandingBetaPage'
import { TrackingSectionContainer } from '../../../components'
import { ROW_TRACKING } from '../utils'
import { usePersonaEvents } from '../../../events'

import styles from './UseCaseSection.module.scss'

const DESKTOP_SCROLL_SPEED = 50
const MOBILE_SCROLL_SPEED = 90

const TopicItem = ({ topic }: { topic: Topic }) => (
  <div
    className={`mc-bg-neutral-900 d-flex flex-row flex-none mc-py-2 mc-px-8 ${styles.item}`}
  >
    <div className='d-flex flex-column justify-content-center mc-px-1mc-gap-1'>
      <p className='mc-text-h6'>{topic.text.displayText}</p>
      <p className='mc-text-h6 mc-text-color--medium mc-text--normal'>
        {topic.text.caption}
      </p>
    </div>
  </div>
)

const ScrollingTopicRow = ({
  topics,
  pauseScroll,
  speedOffset,
  subRowIndex,
}: {
  topics: Topic[]
  pauseScroll: boolean
  speedOffset: number
  subRowIndex: number
}) => {
  const { trackPersonaRowTileViewed } = usePersonaEvents()
  const controlsRef = useRef<AnimationPlaybackControls | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const xTranslation = useMotionValue(0)

  useEffect(() => {
    if (pauseScroll) {
      controlsRef.current?.pause()
    } else {
      controlsRef.current?.play()
    }
  }, [pauseScroll])

  useEffect(() => {
    const width = containerRef.current?.scrollWidth ?? 0
    const finalPosition = -width / 2 - 8 // 8 compensates for gap which is 16px (mc-gap-4)
    const scrollSpeed = isMobile() ? MOBILE_SCROLL_SPEED : DESKTOP_SCROLL_SPEED
    const duration = width / (scrollSpeed + speedOffset * (scrollSpeed / 5))

    const controls = animate(xTranslation, [0, finalPosition], {
      ease: 'linear',
      duration,
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 0,
    })
    controlsRef.current = controls

    return () => {
      controls.stop()
      controlsRef.current = null
    }
  }, [xTranslation, speedOffset])

  const items = [...topics, ...topics]

  return (
    <motion.div
      ref={containerRef}
      className='d-flex mc-gap-4 mc-mb-4'
      style={{ left: 0, x: xTranslation }}
    >
      {items.map((topic, i) => (
        <TrackingContainer
          key={i}
          config={{ threshold: 0.3 }}
          className='d-flex flex-row flex-none'
          onVisible={() =>
            trackPersonaRowTileViewed({
              ...ROW_TRACKING.conversationStarters,
              subrowPositionIndex: subRowIndex.toString(),
              tilePositionIndex: i.toString(),
              tileTitle: topic.text.displayText,
              tileSubtitle: topic.text.caption,
              tileInstructorSlug:
                (topic.analytics?.instructorSlug as string | undefined) || '',
            })
          }
        >
          <TopicItem topic={topic} />
        </TrackingContainer>
      ))}
    </motion.div>
  )
}

type UseCaseSectionProps = {
  topics: Topic[]
}

export const UseCaseSection = ({ topics }: UseCaseSectionProps) => {
  const { t } = useTranslation('@mc/persona')
  const [pauseScroll, setPauseScroll] = useState(false)
  const itemsRows = chunk(topics, Math.ceil(topics.length / 3))

  return (
    <TrackingSectionContainer analytics={ROW_TRACKING.conversationStarters}>
      <div className='mc-py-10 uncontainer' style={{ overflow: 'hidden' }}>
        <h2 className='mc-text-h1 mc-text--center mc-mb-10'>
          {t('upcomingBetaLanding.useCase.title')}
        </h2>
        <motion.div
          onHoverStart={() => setPauseScroll(true)}
          onHoverEnd={() => setPauseScroll(false)}
          onTouchStart={() => setPauseScroll(true)}
          onTouchEnd={() => setPauseScroll(false)}
        >
          {itemsRows.map((itemsRow, rowIndex) => (
            <ScrollingTopicRow
              key={rowIndex}
              topics={itemsRow}
              pauseScroll={pauseScroll}
              speedOffset={rowIndex}
              subRowIndex={rowIndex}
            />
          ))}
        </motion.div>
      </div>
    </TrackingSectionContainer>
  )
}
