import React from 'react'

import { useTranslation } from '@mc/i18n'

import { ROW_TRACKING } from '../utils'
import {
  TrackingSectionContainer,
  Image,
  PersonaEmailCapture,
} from '../../../components'
import { MasterClass } from '../MasterClass'

import styles from './HeroSection.module.scss'

export const HeroSection = () => {
  const { t } = useTranslation('@mc/persona')

  return (
    <TrackingSectionContainer analytics={ROW_TRACKING.hero}>
      <div className='mc-py-9'>
        <div className='row'>
          <div className='col-12 col-md-6 mc-px-10 mc-px-sm-12 mc-p-md-8 position-relative'>
            <div
              className='mc-w-100 mc-h-100 position-relative'
              style={{ zIndex: 1 }}
            >
              <Image
                src='https://static.masterclass.com/persona-pre-pw-logged-out-hero.webp'
                alt={t('upcomingBetaLanding.hero.imageAlt')}
                layout='responsive'
                objectFit='contain'
                priority
                width={801}
                height={888}
              />
            </div>
            <div
              className={`${styles.glowEffect} position-absolute w-100 h-100`}
            />
          </div>
          <div className='col-12 col-md-6 mc-text--center mc-px-sm-12 mc-px-md-10 mc-mt-10 mc-mt-md-0 d-flex flex-column justify-content-center align-items-center'>
            <p className={styles.heroBetaBanner}>
              {t('upcomingBetaLanding.hero.banner')}
            </p>
            <h1 className='mc-font-headline2 mc-my-4'>
              {t('upcomingBetaLanding.hero.title')}
            </h1>
            <p
              className='mc-text-large'
              style={{ whiteSpace: 'pre-wrap', opacity: 0.8 }}
            >
              {t('upcomingBetaLanding.hero.subtitle')}
            </p>
            <div className='mc-w-100 mc-mt-8'>
              <PersonaEmailCapture />
            </div>
            <MasterClass className='d-none d-md-flex' />
          </div>
        </div>
        <MasterClass className='d-flex d-md-none' />
      </div>
    </TrackingSectionContainer>
  )
}
