import React, { useEffect } from 'react'

import { Background, Footer, NavBar } from '../../components'
import {
  usePersonaVideoEnabled,
  useIsLoginHidden,
  useIsUpcomingBetaEnabled,
} from '../../experiments'
import { usePersonaEvents } from '../../events'

import { HeroSection } from './HeroSection'
import { VideoSection } from './VideoSection'
import { InfoSection } from './InfoSection'
import { MentorsSection } from './MentorsSection'
import { UseCaseSection } from './UseCaseSection'
import { TestimonialsSection } from './TestimonialsSection'
import { FaqSection } from './FaqSection'
import { FAQS, ROW_TRACKING } from './utils'

export type Topic = {
  text: { displayText: string; caption: string }
  analytics?: Record<string, string | number | undefined>
  redirectUrl: string
}

export type Testimonial = {
  text: string
  name: string
}

export type Faq = {
  question: string
  answer: string
}

type LoggedOutLandingBetaPageProps = {
  topics: Topic[]
  testimonals: Testimonial[]
}

export const LoggedOutLandingBetaPage = ({
  topics,
  testimonals,
}: LoggedOutLandingBetaPageProps) => {
  const { trackPersonaLandingViewed } = usePersonaEvents()

  useEffect(() => {
    trackPersonaLandingViewed({ isUpcomingBeta: true })
  }, [trackPersonaLandingViewed])

  const isPersonaVideoEnabled = usePersonaVideoEnabled()
  const isLoginHidden = useIsLoginHidden()
  useIsUpcomingBetaEnabled()

  return (
    <Background>
      <div className='container'>
        <NavBar source='discovery' showLogin={!isLoginHidden} />
        <HeroSection />
        {isPersonaVideoEnabled && <VideoSection />}
        <InfoSection />
        <UseCaseSection topics={topics} />
        <MentorsSection />
        <TestimonialsSection testimonals={testimonals} />
        <FaqSection faqs={FAQS} />
        <Footer rowAnalytics={ROW_TRACKING.footer} />
      </div>
    </Background>
  )
}
