export const ROW_TRACKING = {
  hero: {
    rowName: 'hero',
    rowPositionIndex: '0',
    isUpcomingBeta: true,
  },
  broughtToYouBy: {
    rowName: 'brought to you by masterclass',
    rowPositionIndex: 1,
    isUpcomingBeta: true,
  },
  raiseYourExpectations: {
    rowName: 'raise your expectations',
    rowPositionIndex: 2,
    isUpcomingBeta: true,
  },
  aiPoweredByPros: {
    rowName: 'ai powered by the pros',
    rowPositionIndex: 3,
    isUpcomingBeta: true,
  },
  conversationStarters: {
    rowName: 'conversation starters',
    rowPositionIndex: 4,
    isUpcomingBeta: true,
  },
  yourAiMentors: {
    rowName: 'your ai mentors',
    rowPositionIndex: 5,
    isUpcomingBeta: true,
  },
  comingSoon: {
    rowName: 'coming soon',
    rowPositionIndex: 6,
    isUpcomingBeta: true,
  },
  testimonials: {
    rowName: 'testimonials',
    rowPositionIndex: 7,
    isUpcomingBeta: true,
  },
  faqs: {
    rowName: 'faq',
    rowPositionIndex: 8,
    isUpcomingBeta: true,
  },
  footer: {
    rowName: 'tos',
    rowPositionIndex: 9,
    isUpcomingBeta: true,
  },
}

export const FAQS = [
  {
    question: 'What is MasterClass On Call?',
    answer: `MasterClass On Call is a conversational AI product from MasterClass that allows you to voice chat with the AI of some of the world’s most influential and knowledgeable experts. MasterClass On Call gives you unlimited and instant access to personalized advice, insights, and guidance from the world’s best—who are always available to talk through anything big or small.

MasterClass On Call offers personalized world-class wisdom, life advice, direction, and guidance on demand through conversations with our AI mentors anytime through a voice call on our website or iOS app.
`,
  },
  {
    question: 'Why is MasterClass On Call in beta, and what should I expect?',
    answer:
      'MasterClass On Call is in beta phase, which means you get early access ahead of our official launch. We will be testing and refining various features—and we may ask you for feedback. Your feedback is invaluable as we look to enhance the overall experience. Thank you for joining us in shaping the future of MasterClass On Call!',
  },
  {
    question: 'Who is MasterClass On Call for?',
    answer:
      'MasterClass On Call is for anyone looking for new perspectives, craving specialized knowledge, or looking for a judgment-free environment where they can openly discuss what’s on their mind. It’s for anyone who’s ever done a deep-dive on the web in search of answers to their questions, or spent hours chatting with friends or family about how to overcome challenges or reach their full potential.',
  },
  {
    question: 'What does MasterClass On Call include?',
    answer: `MasterClass On Call offers several innovative features:

<strong>Unparalleled Expertise on Demand:</strong> Get unlimited access to the knowledge and experiences of the world’s best—anytime, anywhere. MasterClass On Call is built in partnership with the real-life experts and trained on various data sources, including content exclusive to MasterClass.

<strong>Personalized & Actionable Guidance:</strong> Get tailored advice specific to your unique situation and challenges. Engage in quick or in-depth, one-on-one conversations that adapt to your needs and help you overcome any obstacle.

<strong>Natural, Intuitive Conversations:</strong> Talk things through like you would with a wise friend, always ready to discuss anything big or small—and for as long as you need. Simply chat through the On Call iOS app or website.

<strong>Private and Judgment-Free:</strong> Discuss your challenges openly in a secure setting without fear of judgment. MasterClass is committed to protecting your conversations and data.`,
  },
  {
    question: 'Why is MasterClass On Call reliable?',
    answer:
      'Our unique AI has been meticulously trained on data sources curated by MasterClass in partnership with the real-life expert, which includes some content exclusive to MasterClass. This includes specialized knowledge repositories and real-life stories, enhanced by the direct input and oversight of the real-life experts themselves. This collaboration helps our AI mentors mimic the conversation style and problem-solving approach of their real-life counterparts, and that their advice is insightful, personalized, and relevant.',
  },
  {
    question: 'What devices support MasterClass On Call?',
    answer:
      'MasterClass On Call is accessible on iOS phones and tablets, as well as desktop and mobile web browsers.',
  },
]
