import { useBucket } from '@mc/experiments-react'
import { trackExperimentViewed } from '@mc/track-event'
import { useEffect } from 'react'

// Growthbook experiment: https://app.growthbook.io/features/dais-2235_v1_persona_hide_navbar_login_button
export const PERSONA_HIDE_LOGIN_BUTTON_FLAG =
  'dais-2235_v1_persona_hide_navbar_login_button'

export const useIsLoginHidden = () => {
  const bucket = useBucket<'control' | 'variant_1'>(
    PERSONA_HIDE_LOGIN_BUTTON_FLAG,
  )

  useEffect(() => {
    if (bucket)
      trackExperimentViewed(
        PERSONA_HIDE_LOGIN_BUTTON_FLAG,
        {},
        { trackUserLeap: true },
      )
  }, [bucket])

  return bucket === 'variant_1'
}
