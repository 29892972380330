import React from 'react'

import { useTranslation } from '@mc/i18n'

import { TrackingSectionContainer } from '../../components'
import { ROW_TRACKING } from './utils'

import MCHorizontalLockup from '../../assets/mc-horizontal-lockup.svg'

export const MasterClass = ({
  className = 'd-flex',
}: {
  className?: string
}) => {
  const { t } = useTranslation('@mc/persona')
  return (
    <TrackingSectionContainer
      className={className}
      analytics={ROW_TRACKING.broughtToYouBy}
    >
      <div
        className={`container flex-column align-items-center mc-mt-10 mc-mt-md-8 ${className}`}
      >
        <p className='mc-text-small mc-mb-2'>
          {t('landing.masterclass.above')}
        </p>
        <MCHorizontalLockup />
        <p className='mc-mt-3 mc-text-small mc-text-color--tint'>
          {t('landing.masterclass.below')}
        </p>
      </div>
    </TrackingSectionContainer>
  )
}
